import axios from "./client";
import { InvoiceActions } from "../reducers/InvoiceReducer";
import store from "./../store";
import FileDownload from "js-file-download";
import { GridRowId } from "@mui/x-data-grid";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchInvoicesSevenDays = async () => {
  store.dispatch({ type: InvoiceActions.RequestInvoices, payload: {} });
  const { data: invoices } = await axios.get<Invoice[]>(`${API_URL}invoices/matches`);
  store.dispatch({
    type: InvoiceActions.ReceiveInvoices,
    payload: invoices,
  });
};

export const syncInvoicesLaudus = async () => {
  store.dispatch({ type: InvoiceActions.RequestInvoices, payload: {} });
  await axios.get(`${API_URL}invoices/syncLaudus`);
  store.dispatch({ type: InvoiceActions.StopSyncInvoices, payload: {} });
};

export const updateSentWhatsappInvoice = async (
  invoiceId: string,
  value: boolean
) => {
  store.dispatch({ type: InvoiceActions.RequestInvoices, payload: {} });
  const bodyData = { sentToWhatsApp: value };
  await axios.patch(
    `${API_URL}invoices/${invoiceId}`,
    JSON.stringify(bodyData),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const { data: invoices } = await axios.get<Invoice[]>(`${API_URL}invoices`);
  store.dispatch({
    type: InvoiceActions.ReceiveInvoices,
    payload: invoices,
  });
};
export const generateInvoicesXls = async (downloadName: string) => {
  await axios
    .get(API_URL + `invoices/xls/report`, {
      responseType: "blob",
      headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8",
      },
    })
    .then((res) => {
      FileDownload(res.data, `${downloadName}.xlsx`);
    });
};

export const generateTruckInvoicesXls = async (downloadName: string) => {
  await axios
    .get(API_URL + `invoices/xls/truckReport`, {
      responseType: "blob",
      headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8",
      },
    })
    .then((res) => {
      FileDownload(res.data, `${downloadName}.xlsx`);
    });
};

export const generateTruckXls = async (downloadName: string, truckId: string) => {
  await axios
    .get(API_URL + `invoices/xls/truck/${truckId}`, {
      responseType: "blob",
      headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8",
      },
    })
    .then((res) => {
      FileDownload(res.data, `${downloadName}.xlsx`);
    });
};

export const getPdfUrl = async (invoiceId: string) => {
  const { data: pdfUrl } = await axios.get<string>(
    `${API_URL}invoices/${invoiceId}/pdf`
  );
  return pdfUrl;
};

export const assingTruck = async (truckId: string, selectedInvoices: GridRowId[], assignedTruckDate: Date) => {
  await axios.post(`${API_URL}invoices/assignTruck`, { selectedInvoices, truckId, assignedTruckDate });
};

export const deleteFromTruck = async (invoiceId: string) => {
  await axios.patch(`${API_URL}invoices/${invoiceId}`,
    {
      truck: null,
      assignedTruckDate: null
    });
}

export const assignDepartureHour = async (truckId: string, selectedDate: Date) => {
  try {
  await axios.post(`${API_URL}invoices/assignDepartureHour`, { truck: truckId, departureHour: selectedDate });
  return true;
  }
  catch (error) {
    return false;
  }

}