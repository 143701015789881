import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./AuthReducer";
import invoiceReducer from "./InvoiceReducer";
import truckReducer from "./TrucksReducer";
import pendingOrderReducer from "./PendingOrderReducer";


const rootReducer = combineReducers({
  authReducer,
  invoiceReducer,
  truckReducer,
  pendingOrderReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
