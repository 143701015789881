import axios from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const getLastInputs = async () => {
    try {
        await axios.get(`${API_URL}products/insertLastInputs`);
        return true;
    }
    catch {
        return false;
    }
}