import { Button, Card } from "@mui/material";
import Logo from "../../Logo";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { getPendingOrdersToArray } from "../../../utils/readPendingOrders";
import { fetchPendingOrders, getPendingOrderPDF, uploadPendingOrders } from "../../../services/pendingOrderService";
import Icon from "@mdi/react";
import { mdiCircleSlice1 } from "@mdi/js";
import { DataGridPro, GridColDef, GridToolbar, esES } from "@mui/x-data-grid-pro";
import { getLastInputs } from "../../../services/productService";
import PendingOrderDetailModal from "./PendingOrderDetailModal";


const PendingOrdersView = () => {
    const [selectedPendingOrder, setSelectedPendingOrder] = useState<any>(null);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [loadingInputs, setLoadingInputs] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);

    const columns: GridColDef[] = [
        {
            field: "customerName",
            headerName: "Nombre del cliente",
            width: 200,
        },
        {
            field: "orderDate",
            headerName: "Fecha de orden",
            width: 200,
        },
        {
            field: "branch",
            headerName: "Sucursal",
            width: 200,
        },
        {
            field: "totalProductos",
            headerName: "Total de productos pendientes",
            width: 200,
            valueGetter(params) {
                return params.row.products.reduce(
                    (acc: number, product: any) => acc + product.pendingQuantity,
                    0
                );
            },
        },
        {
            field: "details",
            headerName: "Ver Detalle",
            width: 200,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSelectedPendingOrder(params.row);
                            setOpenDetailsModal(true);
                        }}
                    >
                        Ver Detalle
                    </Button>
                );
            },
        },
        {
            field: "pdf",
            headerName: "PDF",
            width: 200,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        disabled={loadingPdf}
                        onClick={async () => {
                            setLoadingPdf(true);
                            await getPendingOrderPDF(params.row._id);
                            setLoadingPdf(false);
                        }}
                    >
                        Generar PDF
                    </Button>
                );
            },
        }
    ];
    const { isLoading, pendingOrders } = useAppSelector(
        (state: any) => state.pendingOrderReducer
    );

    const handleCustomerOrderUpload = (e: any) => {
        const file = e.target.files[0];
        getPendingOrdersToArray(file).then((data) => {
            uploadPendingOrders(data).then((res) => {
                if (res) {
                    alert("Ordenes cargadas correctamente")
                }
                else {
                    alert("Error al cargar las ordenes en el servidor")
                }
            })
        }).catch((err) => {
            alert(err)
        }).finally(() => {
            e.target.value = "";
        })
    }
    useEffect(() => {
        fetchPendingOrders();
    }, []);
    return (
        <div className="p-5 h-full overflow-auto">
            <Logo />
            <div className="text-xl mb-5">Ordenes Pendientes</div>
            <div className="mb-3 flex justify-between pr-7 pl-7">
                <Button
                    variant="contained"
                    disabled={loadingInputs}
                    onClick={() => {
                        setLoadingInputs(true);
                        getLastInputs().then((res) => {
                            if (res) {
                                alert("Inputs actualizados correctamente")
                            }
                            else {
                                alert("Error al actualizar los inputs")
                            }
                        }).finally(() => {
                            setLoadingInputs(false);
                        })
                    }}
                >
                    Actualizar inputs de Laudus
                </Button>
                <div>
                    <input
                        accept=".xls, .xlsx"
                        style={{ display: "none" }}
                        id="customerorder-button-file"
                        type="file"
                        onChange={(e) => handleCustomerOrderUpload(e)}
                    />
                    <label htmlFor="customerorder-button-file">
                        <Button
                            variant="contained"
                            component="span"
                        >
                            Carga Masiva de ordenes pendientes
                        </Button>
                    </label>
                </div>
            </div>
            {isLoading ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                </div>
            ) : (
                <Card>
                    <DataGridPro
                        sx={{ padding: 2 }}
                        autoHeight={true}
                        columns={columns}
                        rows={pendingOrders}
                        pageSize={20}
                        getRowId={(row) => row._id}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        pagination

                    />
                    <PendingOrderDetailModal
                        open={openDetailsModal}
                        handleClose={() => setOpenDetailsModal(false)}
                        pendingOrder={selectedPendingOrder}
                    />
                </Card>

            )}
        </div>
    )
}

export default PendingOrdersView