import axios from "./client";
import { TruckActions } from "../reducers/TrucksReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchTrucks = async () => {
    store.dispatch({ type: TruckActions.RequestTrucks, payload: {} });
    const { data: trucks } = await axios.get<Truck[]>(`${API_URL}truck`);
    store.dispatch({
        type: TruckActions.ReceiveTrucks,
        payload: trucks,
    });
};
export const deleteTruck = async (id: string) => {
    const { data: truck } = await axios.delete<Truck>(
        `${API_URL}truck/${id}`
    );
    store.dispatch({
        type: TruckActions.DeleteTruck,
        payload: truck,
    });
};
export const updateTruck = async (id: string, truck: Partial<Truck>) => {
    const { data: editedTruck } = await axios.patch<Truck>(
        `${API_URL}truck/${id}`,
        truck
    );
    store.dispatch({
        type: TruckActions.AddTruck,
        payload: editedTruck,
    });
};
export const addTruck = async (truck: Partial<Truck>) => {
    const { data: newTruck } = await axios.post<Truck>(
        `${API_URL}truck/`,
        truck
    );
    store.dispatch({
        type: TruckActions.AddTruck,
        payload: newTruck,
    });
};
export const changePassword = async (body: {
    username: string;
    password: string;
}) => {
    try {
        const { data: success } = await axios.post<boolean>(
            `${API_URL}truck/passwordchange`,
            body
        );
        return success;
    } catch (error) {
        console.log(error);
        return false;
    }
};