export enum LogisticRoutes {
  Logistics = "/logistic/logistics",
  Trucks = "/logistic/trucks",
  PendingOrders = "/logistic/pendingorders",
}

export enum Routes {
  Home = "/",
  Login = "/login",
}
