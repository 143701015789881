import {
    DataGridPro,
    GridColDef,
    GridRenderCellParams,
    GridRowId,
    esES,
} from "@mui/x-data-grid-pro";
import { Autocomplete, Button, Card, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { AssignTruckModal } from "./AssignTruckModal";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import { assingTruck, fetchInvoicesSevenDays, generateTruckInvoicesXls } from "../../../services/invoiceService";
import { DownloadOutlined, } from "@mui/icons-material";
import { startIcon } from "../../../utils/invoiceStateIconRencer";


export const InvoicesView: React.FC = () => {
    const { isLoading, invoices } = useAppSelector(
        (state: any) => state.invoiceReducer
    );
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([])
    const [possibleInvoice, setPossibleInvoice] = useState("");

    const { trucks } = useAppSelector((state: any) => state.truckReducer);
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const [downloadingFile, setDownloadingFile] = useState(false);

    const assignTruckToSelectedInvoices = async (truckId: string, assignedTruckDate: Date) => {
        await assingTruck(truckId, selectedRows, assignedTruckDate);
        await fetchInvoicesSevenDays();
    };

    const columns: GridColDef[] = [
        {
            field: "id",
            minWidth: 100,
            flex: 1,
            headerName: "ID Factura",

            valueGetter(params) {
                return params.row.invoiceLaudusId;
            },
        },
        {
            field: "commune",
            minWidth: 100,
            flex: 1,
            headerName: "Comuna",
            valueGetter(params) {
                return params.row.commune;
            }
        },
        {
            field: "customer",
            minWidth: 200,
            flex: 1,
            headerName: "Cliente",
            valueGetter(params) {
                return params.row.name;
            },
        },
        {
            field: "matchingOrdersCount",
            minWidth: 200,
            flex: 1,
            headerName: "Ordenes Pendientes",
        },
        {
            field: "status",
            minWidth: 200,
            flex: 1,
            headerName: "Estado",
            renderCell(params: GridRenderCellParams) {
                return (
                    <>
                        {startIcon(params.row.status)}
                        {params.row.status}

                    </>
                )
            }
        },
        {
            field: "fecha",
            minWidth: 100,
            flex: 1,
            headerName: "Fecha Factura",
            valueGetter(params) {
                const date = new Date(params.row.date);
                return `${date.getDate()}/${date.getMonth() + 1
                    }/${date.getFullYear()} `;
            },
        },
        {
            field: "fecha pedido",
            minWidth: 100,
            flex: 1,
            headerName: "Fecha Pedido",
            valueGetter(params) {
                if (!params.row.orderId) {
                    return "Pedido sin Orden"
                }
                const date = new Date(params.row.orderId);
                return `${date.getDate()}/${date.getMonth() + 1
                    }/${date.getFullYear()} `;
            }
        },
        {
            field: "sellerName",
            minWidth: 200,
            flex: 1,
            headerName: "Nombre Vendedor",
            valueGetter(params) {
                return params.row.sellerName;
            },
        },

    ];

    return (
        <div>
            {isLoading ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                </div>
            ) : (
                <div>
                    <Card
                        sx={{
                            borderRadius: 2,
                            padding: 2,
                            margin: 2,
                        }}
                    >
                        <DataGridPro
                            columns={columns}
                            rows={invoices.filter((invoice: Invoice) => !invoice.truck && selectedRows.includes(invoice._id))}
                            checkboxSelection
                            onSelectionModelChange={(newSelection) => {
                                setSelectedRows(newSelection);
                            }}
                            selectionModel={selectedRows}
                            autoHeight={true}
                            pagination
                            pageSize={10}
                            getRowId={(row) => row._id}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                        <Grid container direction="row" justifyContent="space-around"
                            alignItems="center">

                            <TextField
                                label="Factura"
                                value={possibleInvoice}
                                onChange={(e) => {
                                    setPossibleInvoice(e.target.value)
                                }
                                }
                            />
                            <Button
                                disabled={selectedRows.length === 0}
                                sx={{
                                    margin: 2,
                                }}
                                variant="contained"
                                onClick={handleOpenModal}>Asignar</Button>
                            <Button
                                sx={{
                                    margin: 2,
                                }}
                                disabled={downloadingFile}
                                variant="contained"
                                onClick={async () => {
                                    setDownloadingFile(true);
                                    const downloadName = `${new Date(Date.now()).getTime()}`;
                                    await generateTruckInvoicesXls(downloadName);
                                    setDownloadingFile(false);
                                }}
                                startIcon={<DownloadOutlined />}
                            >
                                Generar Excel</Button>
                        </Grid>
                        <AssignTruckModal
                            open={modalOpen}
                            handleClose={handleCloseModal}
                            trucks={trucks}
                            assignTruck={assignTruckToSelectedInvoices}
                        />

                        <DataGridPro
                            sx={{
                                padding: 0,
                                "& .super-app-theme--true": {
                                    bgcolor: "#9ACD32",
                                },
                                border: 0,
                            }}
                            checkboxSelection
                            onSelectionModelChange={(newSelection) => {
                                setSelectedRows(newSelection);
                            }}
                            selectionModel={selectedRows}
                            autoHeight={true}
                            columns={columns}
                            rows={possibleInvoice ? invoices.filter((invoice: Invoice) => !invoice.truck && invoice.invoiceLaudusId.includes(possibleInvoice)) :
                                invoices.filter((invoice: Invoice) => !invoice.truck)}
                            pagination
                            pageSize={20}
                            getRowId={(row) => row._id}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Card>
                </div>
            )
            }
        </div >
    );
};
