import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Card,
    CardContent,
    Typography,
} from "@mui/material";

interface PendingOrderModalProps {
    open: boolean;
    handleClose: () => void;
    pendingOrder: PendingOrder | null;
}

export const PendingOrderDetailModal: React.FC<PendingOrderModalProps> = ({
    open,
    handleClose,
    pendingOrder,
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <Card
                sx={{
                    padding: "1rem",
                }}
            >
                <DialogTitle>Detalle de la orden</DialogTitle>
                <DialogContent sx={{ overflowY: 'auto', maxHeight: '70vh' }}>
                    {pendingOrder?.products.map((product) => (
                        <Card key={product.code} sx={{ marginBottom: 2 }}>
                            <CardContent>
                                <Typography variant="body2" component="p">SKU: {product.code}</Typography>
                                <Typography variant="body2" component="p">Descripción: {product.description}</Typography>
                                <Typography variant="body2" component="p">Cantidad: {product.quantity}</Typography>
                                <Typography variant="body2" component="p">Pendiente: {product.pendingQuantity}</Typography>
                                <Typography variant="body2" component="p">Pendiente Precio: {product.pendingValue}</Typography>
                                <Typography variant="body2" component="p">Stock Total: {product.stockTotal}</Typography>
                                <Typography variant="body2" component="p">Última entrada en Arturo Prat: {(new Date(product.lastInputArturoPratDate)).toLocaleDateString()}</Typography>
                                <Typography variant="body2" component="p">Cantidad última entrada en Arturo Prat: {product.lastInputArturoPratQuantity}</Typography>
                            </CardContent>
                        </Card>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        color="primary"
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Card>
        </Dialog>
    );
}

export default PendingOrderDetailModal;