import React from "react";
import { useAppSelector } from "../hooks/storeHooks";
import { Menu as LogisticMenu } from "./Menu";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const loggedIn = useAppSelector((s) => !!s.authReducer.token);
  const role = useAppSelector((s) => s.authReducer.user?.role);
  return (
    <div className="h-screen md:flex" style={{ marginTop: 64 }}>

      {
        loggedIn && role === "logistic" && (
          <LogisticMenu />
        )
      }

      <div className="flex-1 bg-pineWhite mt-5">{children}</div>
    </div>
  );
};

export default Layout;
