import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { useForm } from "../../../hooks/useForm";
import { addTruck, updateTruck } from "../../../services/truckService";
import { useEffect } from "react";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    truck: Truck | null;
    setTruck: (truck: Truck | null) => void;
}

const NewTruckModal: React.FC<Props> = ({
    open,
    onClose,
    truck,
    setTruck,
}) => {
    const initialValues = {
        username: "",
    };
    const [values, setValues, handleChange] = useForm(initialValues);
    useEffect(() => {
        if (truck) {
            setValues({
                ...values,
                username: truck.username,
            });
        } else {
            setValues(initialValues);
        }
    }, [truck]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onTransitionEnd={() => !open && setValues(initialValues)} //para borrar los values solo cuando se cierra
        >
            <DialogTitle>Nuevo Camión</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-2 mt-5">
                    <TextField
                        onChange={handleChange}
                        id="username"
                        label="Usuario"
                        value={values.username}
                    ></TextField>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        if (truck !== null) {
                            setTruck(null);
                            setValues(initialValues);
                        }
                        onClose();
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    variant="contained"
                    disabled={
                        values.username === ""
                    }
                    onClick={async () => {
                        if (truck) {
                            await updateTruck(truck._id, values);
                            setTruck(null);
                            onClose();
                        } else {
                            await addTruck(values);
                            setTruck(null);
                            onClose();
                        }
                    }}
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewTruckModal;
