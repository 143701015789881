interface State {
    pendingOrders: PendingOrder[];
    isLoading: boolean;
}

const initialState: State = {
    pendingOrders: [],
    isLoading: false,
};

export enum PendingOrderActions {
    RequestPendingOrders = "REQUEST_PENDING_ORDERS",
    ReceivePendingOrders = "RECEIVE_PENDING_ORDERS",
}

const reducer = (
    state: State = initialState,
    { type, payload }: { type: PendingOrderActions; payload: any }
): State => {
    switch (type) {
        case PendingOrderActions.RequestPendingOrders:
            return { ...state, isLoading: true };
        case PendingOrderActions.ReceivePendingOrders:
            return { ...state, pendingOrders: payload, isLoading: false };
        default:
            return state;
    }
};

export default reducer;