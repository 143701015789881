import React from 'react'
import { useAppSelector } from '../../../hooks/storeHooks';
import { TruckRow } from './TruckRow';
import Icon from "@mdi/react";
import { mdiCircleSlice1 } from '@mdi/js';
import { Paper } from '@mui/material';

export const TrucksView = () => {
    const { isLoading, trucks } = useAppSelector(
        (state: any) => state.truckReducer
    );
    const { invoices } = useAppSelector(
        (state: any) => state.invoiceReducer
    );



    return (
        <div>
            {isLoading ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                </div>
            ) : (
                <Paper
                    sx={{
                        padding: 2,
                    }}
                >
                    {trucks.map((truck: Truck) => (
                        <TruckRow key={truck._id} truck={truck} invoices={invoices.filter((invoice: Invoice) => invoice.truck === truck._id)} />
                    ))}
                </Paper>
            )
            }
        </div >
    )
};
