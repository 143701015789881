import { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


interface AssignTruckModalProps {
    open: boolean;
    handleClose: () => void;
    trucks: Truck[];
    assignTruck: (truckId: string, assignTruckDate: Date) => void;
}

export const AssignTruckModal: React.FC<AssignTruckModalProps> = ({
    open,
    handleClose,
    trucks,
    assignTruck,
}) => {
    const [selectedTruck, setSelectedTruck] = useState("");
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs(new Date(Date.now())));

    const handleAssign = () => {
        assignTruck(selectedTruck, selectedDate.toDate());
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}
            sx={{
                "& .MuiDialog-paper": {
                    width: "30rem",
                },
            }}
        >
            <DialogTitle>Asignar camión</DialogTitle>
            <DialogContent>
                <DialogContentText
                    sx={{
                        marginBottom: "1rem",
                    }}
                >
                    Selecciona un camión y una fecha de despacho.
                </DialogContentText>
                <FormControl fullWidth>
                    <Select
                        value={selectedTruck}
                        onChange={(event) => setSelectedTruck(event.target.value)}
                    >
                        {trucks.map((truck) => (
                            <MenuItem key={truck._id} value={truck._id}>
                                {truck.username}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        sx={{
                            marginBottom: 2,
                            marginTop: 2,
                            width: 300,
                        }}
                        format="DD/MM/YYYY"
                        label="Fecha"
                        value={selectedDate}
                        onChange={(newValue) => {
                            setSelectedDate(newValue!);
                        }}
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleAssign} disabled={!selectedTruck}>
                    Asignar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
