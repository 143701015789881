import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
} from "@mui/material";

interface ObservationsModalProps {
  open: boolean;
  handleClose: () => void;
  invoice: Invoice | null;
}

export const ObservationsModal: React.FC<ObservationsModalProps> = ({
  open,
  handleClose,
  invoice,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "30rem",
        },
      }}
    >
      <Card
        sx={{
          padding: "1rem",
        }}
      >
        <DialogTitle>Observaciones</DialogTitle>
        <DialogContent>
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {invoice?.observations && <DialogContentText>
              Observación: {invoice?.observations}
            </DialogContentText>}
            {invoice?.photo.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt="preview"
                style={{
                  width: "100%",
                  height: "100%",
                  margin: "auto",
                  marginTop: "1rem",
                }}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#ff0000",
            }}
            onClick={handleClose}
          >
            Salir
          </Button>
        </DialogActions>
      </Card>
    </Dialog>
  );
};
