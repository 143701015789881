import { SetStateAction, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { assignDepartureHour, fetchInvoicesSevenDays } from "../../../services/invoiceService";


interface AssignTruckModalProps {
    open: boolean;
    handleClose: () => void;
    truckId: string;
}

const AssignTruckModal: React.FC<AssignTruckModalProps> = ({
    open,
    handleClose,
    truckId,

}) => {
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs(new Date(Date.now())));

    const handleAssign = () => {
        assignDepartureHour(truckId, selectedDate.toDate()).then(
            async (res) => {
                if (res) {
                    alert("Hora de salida asignada correctamente");
                    await fetchInvoicesSevenDays();
                    handleClose();
                }
                else {
                    alert("Error al asignar hora de salida");
                }
            }
        )
    };

    return (
        <Dialog open={open} onClose={handleClose}
            sx={{
                "& .MuiDialog-paper": {
                    width: "30rem",
                },
            }}
        >
            <DialogTitle>Asignar hora de salida</DialogTitle>
            <DialogContent>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        label="Hora Salida"
                        value={selectedDate}
                        onChange={(newValue: Dayjs | ((prevState: Dayjs) => Dayjs) | null) => {
                            if (typeof newValue === 'function') {
                                setSelectedDate(newValue(selectedDate));
                            } else if (newValue !== null) {
                                setSelectedDate(newValue);
                            }
                        }}

                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleAssign}>
                    Asignar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssignTruckModal;