import {
    DataGridPro,
    GridRenderCellParams,
    GridColDef,
    esES,
    GridToolbar,
} from "@mui/x-data-grid-pro";
import { Button, Card, Alert } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import {
    fetchTrucks,
    deleteTruck,
    changePassword,
} from "../../../services/truckService";
import NewTruckModal from "./NewTruckModal";
import EditPasswordModal from "./EditPasswordModal";
import Logo from "../../Logo";


const TrucksView: React.FC = () => {
    const { isLoading, trucks } = useAppSelector(
        (state: any) => state.truckReducer
    );
    const effectRan = useRef(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const [selectedTruck, setSelectedTruck] = useState<Truck | null>(null);
    const [successAlert, setSuccessAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const columns: GridColDef[] = [
        {
            field: "username",
            minWidth: 150,
            flex: 1,
            headerName: "Usuario",
        },
        {
            field: "passwordChange",
            minWidth: 150,
            flex: 1,
            headerName: "Contraseña",
            renderCell(params) {
                return (
                    <Button
                        sx={{ backgroundColor: "orange", color: "white" }}
                        onClick={() => {
                            for (let i = 0; i < trucks.length; i++) {
                                if (trucks[i]._id === params.row._id) {
                                    setSelectedTruck(trucks[i]);
                                    setPasswordModalOpen(true);
                                }
                            }
                        }}
                    >
                        {" "}
                        Cambiar Clave
                    </Button>
                );
            },
        },
        {
            field: "delete",
            minWidth: 100,
            flex: 1,
            headerName: "Eliminar",
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    sx={{ backgroundColor: "red", color: "white" }}
                    onClick={async () => {
                        await deleteTruck(params.row._id)
                        await fetchTrucks();
                    }}
                >
                    Eliminar
                </Button>
            ),
        },
    ];

    useEffect(() => {
        if (effectRan.current === false) {
            fetchTrucks();
            return () => {
                effectRan.current = true
            }
        }
    }, []);

    return (
        <div className="p-5 h-full overflow-auto">
            <Logo />
            <div className="text-xl mb-5">Camiones</div>
            <div className="mb-3 flex justify-end pr-7">
                <Button
                    variant="contained"
                    onClick={() => {
                        setSelectedTruck(null);
                        setModalOpen(true);
                    }}
                >
                    {" "}
                    Agregar
                </Button>
            </div>
            {successAlert && (
                <Alert severity="success" onClose={() => setSuccessAlert(false)}>
                    Contraseña cambiada con éxito
                </Alert>
            )}
            {errorAlert && (
                <Alert severity="error" onClose={() => setErrorAlert(false)}>
                    Error al cambiar contraseña
                </Alert>
            )}
            <div>
                {isLoading ? (
                    <div className="flex justify-center w-full col-span-full">
                        <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                    </div>
                ) : (
                    <Card>
                        <DataGridPro
                            sx={{ padding: 2 }}
                            autoHeight={true}
                            columns={columns}
                            rows={trucks}
                            pageSize={20}
                            getRowId={(row) => row._id}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                        />
                        <NewTruckModal
                            open={modalOpen}
                            truck={selectedTruck}
                            setTruck={setSelectedTruck}
                            onClose={() => setModalOpen(false)}
                        />
                        <EditPasswordModal
                            open={passwordModalOpen}
                            truck={selectedTruck}
                            setTruck={setSelectedTruck}
                            onClose={() => setPasswordModalOpen(false)}
                            onSubmit={(body: { password: string; username: string }) => {
                                changePassword(body)
                                    .then((res) => {
                                        if (res === true) {
                                            setSuccessAlert(true);
                                        } else {
                                            setErrorAlert(true);
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        setErrorAlert(true);
                                    });
                            }}
                        />
                    </Card>
                )}
            </div>
        </div>
    );
};
export default TrucksView;
