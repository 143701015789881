import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DeleteForever, Download, FireTruck, } from "@mui/icons-material";
import { deleteFromTruck, fetchInvoicesSevenDays, generateTruckXls } from "../../../services/invoiceService";
import { useEffect, useRef, useState } from "react";
import { ObservationsModal } from "./ObservationsModal";
import { startIcon } from "../../../utils/invoiceStateIconRencer";
import AssignDepartureHourModal from "./AssignDepartureHourModal";

interface TruckRowProps {
    truck: Truck;
    invoices: Invoice[];
}

export const TruckRow: React.FC<TruckRowProps> = ({ truck, invoices }) => {

    const [truckInvoicesOfToday, setTruckInvoicesOfToday] = useState<Invoice[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [openAssignDepartureHourModal, setOpenAssignDepartureHourModal] = useState(false);
    const effectRan = useRef(false);

    useEffect(() => {
        if (!effectRan.current) {
            setTruckInvoicesOfToday(invoices.filter((invoice) => {
                const today = new Date();
                const invoiceDate = new Date(invoice.assignedTruckDate);
                return (
                    today.getDate() === invoiceDate.getDate() &&
                    today.getMonth() === invoiceDate.getMonth() &&
                    today.getFullYear() === invoiceDate.getFullYear()
                );
            }))
        }
        return () => {
            effectRan.current = true;
        };
    }, []);

    const [isDownloading, setIsDownloading] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };


    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
    const renderInvoicesTable = () => {
        return (
            <>
                <div className="flex gap-2">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{
                            marginBottom: 2,
                        }}
                        startIcon={<Download />}
                        onClick={async () => {
                            setIsDownloading(true);
                            await generateTruckXls(`${truck.username}_Facturas`, truck._id);
                            setIsDownloading(false);
                        }}
                        disabled={isDownloading}
                    >
                        Descargar
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{
                            marginBottom: 2,
                        }}
                        onClick={async () => {
                            setOpenAssignDepartureHourModal(true)
                        }}
                    >
                        Asignar Hora de salida
                    </Button>
                </div>
                <TableContainer
                    component={Paper}
                    sx={{
                        height: 500,
                        backgroundColor: "#f5f5f5"
                    }}
                >
                    <Table aria-label="facturas asignadas a camión"
                    >
                        <TableHead
                            sx={{
                                backgroundColor: "#e0e0e0"
                            }}
                        >
                            <TableRow
                            >
                                <TableCell>ID Factura</TableCell>
                                <TableCell>Cliente</TableCell>
                                <TableCell>Comuna</TableCell>
                                <TableCell>Fecha asignación</TableCell>
                                <TableCell>Ultima Actualización</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Eliminar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                height: "max-content"
                            }}
                        >
                            {invoices.sort(
                                (a, b) =>
                                    new Date(b.assignedTruckDate).getTime() -
                                    new Date(a.assignedTruckDate).getTime()
                            ).map((invoice) => (
                                <TableRow key={invoice.invoiceLaudusId}>
                                    <TableCell>{invoice.invoiceLaudusId}</TableCell>
                                    <TableCell>{invoice.name}</TableCell>
                                    <TableCell>{invoice.commune}</TableCell>
                                    <TableCell>
                                        {new Date(invoice.assignedTruckDate).toLocaleString("es-CL", {
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false // Ajusta según prefieras 24 horas o 12 horas
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        {invoice.changedStatusDate ? new Date(invoice.changedStatusDate).toLocaleString("es-CL", {
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false // Ajusta según prefieras 24 horas o 12 horas
                                        }) : "Sin cambios"}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            disabled={invoice.status === 'PENDIENTE DESPACHO' || invoice.status === 'REPROGRAMADO'}
                                            onClick={() => {
                                                setSelectedInvoice(invoice);
                                                handleOpenModal();
                                            }}
                                            startIcon={startIcon(invoice.status)}
                                        >{invoice.status}</Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            color="error"
                                            size="small"
                                            onClick={async () => {
                                                await deleteFromTruck(invoice._id)
                                                await fetchInvoicesSevenDays();
                                            }}
                                            disabled={(invoice.status === "ENTREGADO CON OBSERVACIONES" || invoice.status === "ENTREGADO SIN OBSERVACIONES")}
                                        >
                                            <DeleteForever />
                                        </Button>
                                    </TableCell>
                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <AssignDepartureHourModal
                    open={openAssignDepartureHourModal}
                    handleClose={() => setOpenAssignDepartureHourModal(false)}
                    truckId={truck._id}
                />
            </>

        );
    };

    return (

        (invoices.length > 0) ?
            (
                <>
                    <Accordion
                        sx={{
                            color: "primary.contrastText",
                            margin: "1px",
                            borderRadius: "10px",
                        }}
                    >
                        <AccordionSummary
                            sx={{
                                backgroundColor: "primary.main",
                                borderRadius: "10px",
                            }}
                            expandIcon={<ExpandMoreIcon
                                sx={{
                                    color: "white",
                                }}
                            />}>

                            <FireTruck
                                sx={{
                                    color: "white",
                                    mr: 1,
                                }}
                            />

                            <Typography
                            >{truck.username}</Typography>
                            <Grid container direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                <Chip
                                    label={`Total facturas: ${invoices.length}`}
                                    color="warning"
                                    sx={{
                                        mr: 1,
                                    }}

                                />
                                <Chip
                                    label={`Del día: ${truckInvoicesOfToday.length}`}
                                    color="success"
                                    sx={{
                                        mr: 1,
                                    }}

                                />
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails
                        >{renderInvoicesTable()}
                        </AccordionDetails>

                    </Accordion>
                    <ObservationsModal
                        open={modalOpen}
                        handleClose={handleCloseModal}
                        invoice={selectedInvoice}
                    />
                </>
            )
            : null
    );
};

