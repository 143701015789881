import axios from "./client";
import { PendingOrderActions } from "../reducers/PendingOrderReducer";
import store from "../store";

const API_URL = process.env.REACT_APP_API_URL;

export const uploadPendingOrders = async (pendingOrders: PendingOrder[]) => {
    try {
        await axios.post(`${API_URL}pendingOrder/bulk`,
        pendingOrders)
        await fetchPendingOrders();
        return true;
    }
    catch {
        return false; 
    }
}

export const fetchPendingOrders = async () => {
    try {
        store.dispatch({ type: PendingOrderActions.RequestPendingOrders, payload: {} });
        const { data: pendingOrders }  = await axios.get<PendingOrder[]>(`${API_URL}pendingOrder`);
        store.dispatch({ type: PendingOrderActions.ReceivePendingOrders, payload: pendingOrders });
        
    }
    catch {
        store.dispatch({ type: PendingOrderActions.RequestPendingOrders, payload: [] });
    }

}

export const getPendingOrderPDF = async (id: string) => { 
    await axios.get(`${API_URL}pendingOrder/${id}/pdf`, {
        responseType: "blob",
        headers: {
            "content-type": "application/pdf",
        },
    }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `pendingOrder-${id}.pdf`);
        document.body.appendChild(link);
        link.click();
    });
}