import Excel from "exceljs";

export async function getPendingOrdersToArray(file) {
    const reader = new FileReader();
    const wb = new Excel.Workbook();
    reader.readAsArrayBuffer(file);
    return new Promise((resolve, reject) => {
            reader.onload = () => {
              const buffer = reader.result;
              let rowsToArray = [];
              wb.xlsx
                .load(buffer)
                .then((workbook) => {
                  workbook.worksheets[0].eachRow((row, rowNumber) => {
                    let rowToArray = {};
                    if(rowNumber > 1) {
                        if(row.values[7] === undefined) {
                            
                        }
                      else if(row.values[1] !== undefined && 
                        row.values[6] !== undefined && 
                        row.values[8] !== undefined && 
                        row.values[14] !== undefined && 
                        row.values[21] !== undefined &&
                        row.values[4] !== undefined &&
                        row.values[9] !== undefined &&
                        row.values[10] !== undefined &&
                        row.values[17] !== undefined) {

                        rowToArray['orderNumber'] = parseInt(row.values[1])
                        rowToArray['orderDate'] = new Date(parseInt(row.values[7]))
                        rowToArray['sellerLaudusName'] = row.values[6]
                        rowToArray['redpineId'] = parseInt(row.values[7])
                        rowToArray['customerName'] = row.values[4]
                        rowToArray['branch'] = row.values[21]
                        rowToArray['code'] = row.values[8].toString()
                        rowToArray['description'] = row.values[9]
                        rowToArray['quantity'] = parseInt(row.values[10])
                        if(parseInt(row.values[14]) < 0) {
                            rowToArray['pendingQuantity'] = 0
                        }
                        else {
                            rowToArray['pendingQuantity'] = parseInt(row.values[14])
                        }
                        if(parseInt(row.values[17]) < 0) {
                            rowToArray['pendingValue'] = 0
                        }
                        else {
                            rowToArray['pendingValue'] = parseInt(row.values[17])
                        }
                    rowsToArray.push(rowToArray);
                    }
                    else {
                      reject('Error en la fila ' + rowNumber + ' del archivo')
                    }
                  }
                })
            }).then(() => {
                // convert to PendingOrder object
                const pendingOrders = rowsToArray.reduce((acc, row) => {
                    // crear una clave unica basada en orderNumber, orderDate, redpineId, sellerLaudusName, customerName y branch
                    const key = `${row.orderNumber}-${row.orderDate}-${row.redpineId}-${row.sellerLaudusName}-${row.customerName}-${row.branch}`;
                    // si la clave no existe en el acumulador, la creamos
                    if (!acc[key]) {
                      acc[key] = {
                        orderNumber: row.orderNumber,
                        orderDate: row.orderDate,
                        sellerLaudusName: row.sellerLaudusName,
                        redpineId: row.redpineId,
                        customerName: row.customerName,
                        branch: row.branch,
                        products: [],
                      };
                    }
                    // añadimos el producto a la orden
                    acc[key].products.push({
                      code: row.code,
                      description: row.description,
                      quantity: row.quantity,
                      pendingQuantity: row.pendingQuantity,
                      pendingValue: row.pendingValue,
                    });
                    return acc;
                    }
                    , {});
                resolve(Object.values(pendingOrders));
              });

        };
      });
   
    }